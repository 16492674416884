import { useRouteError } from "react-router-dom";
import { Page404 } from "../404";

export const ErrorBoundary = () => {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (
    <div>
      <Page404 />
    </div>
  );
};
