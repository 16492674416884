import React, { useEffect, useState } from "react";
import { NoneBorderCard } from "./NoneBorderCard";

export const CarouselComponent = ({
  direction,
  data,
  imageClass,
  imageContainer,
}) => {
  const [slides, setSlides] = useState(data);

  const handleActiveItem = (item) => {
    setSlides((prev) =>
      prev.map((prevItem) => {
        return {
          ...prevItem,
          isActive: item.id === prevItem.id ? true : false,
        };
      })
    );
  };

  const handleAutoPlay = () => {
    console.log({ slides });
    if (slides.length > 1) {
      setSlides((slides) => {
        return slides.map((slide) => {
          return {
            ...slide,
            isActive: !slide.isActive,
          };
        });
      });
    }
  };

  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      handleAutoPlay();
    }, 8000);

    return () => clearInterval(autoplayInterval);
  }, []);

  return (
    <div
      className={`my-[30px] flex flex-col lg:flex-row items-center justify-center pl-0 py-0 relative self-stretch w-full ${
        direction === "left" && "lg:flex-row-reverse"
      } `}
    >
      {slides.map((item) => {
        return (
          item.isActive && (
            <React.Fragment key={item.id + item.title}>
              <div
                className={`${
                  imageContainer ?? imageContainer
                } lg:w-[50%] flex items-center justify-center animate__animated my-5 ${
                  direction === "left"
                    ? "animate__backInRight"
                    : "animate__backInLeft"
                }`}
              >
                <img
                  className={imageClass ?? "relative w-[100%]"}
                  src={item.image}
                  alt={item.title}
                />
              </div>
              <div
                className={`${
                  direction === "left" ? "lg:ml-44" : "lg:mr-44"
                } flex flex-col items-start lg:gap-[43px] relative flex-1 grow`}
              >
                <div className="relative flex w-full lg:gap-[10px] lg:h-10 lg:mb-0 mb-5 cursor-pointer">
                  {slides.map((item) => (
                    <div
                      key={item.id}
                      className={`carousel-slider ${
                        item.isActive ? "" : "opacity-[20.2%]"
                      }`}
                      onClick={() => handleActiveItem(item)}
                    ></div>
                  ))}
                </div>

                <NoneBorderCard
                  title={item.title}
                  titleClass={
                    "section-subtitle [font-family:'Montserrat',Helvetica] font-medium relative self-stretch text-white tracking-[0] leading-[normal]"
                  }
                  description={
                    <p className="section-text relative self-stretch [font-family:'Montserrat',Helvetica] font-normal text-white tracking-[0.20px] leading-[26px]">
                      {item.description}
                    </p>
                  }
                  buttonTitle={"Start now"}
                  btnTitleClass={
                    "button-text relative w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-semibold text-[#00d1e1] tracking-[0] leading-[normal] whitespace-nowrap"
                  }
                  btnClass={
                    "inline-flex items-center gap-[10px] relative bg-transparent border-none mb-10 lg:mb-0"
                  }
                  sectionClass={`animate__animated ${
                    direction === "left"
                      ? "animate__backInLeft"
                      : "animate__backInRight"
                  } flex flex-col items-start gap-[22px] relative self-stretch w-full`}
                  descriptionClass={
                    "relative self-stretch [font-family:'Montserrat',Helvetica] font-normal text-white tracking-[0] leading-[24px]"
                  }
                  buttonType={"no-border"}
                  path={item.path}
                  pathTarget={"_blank"}
                />
              </div>
            </React.Fragment>
          )
        );
      })}
    </div>
  );
};
