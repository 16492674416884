import React, { useEffect, useState } from "react";
import { NoneBorderCard } from "../../../components/NoneBorderCard";
import "../style/home-page-style.css";

export const BannerSection = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsActive(true);
    }, 5000);
  }, []);

  return (
    <section className="text-center lg:text-left h-screen relative flex w-full items-center bg-[rgb(4,20,55)]">
      <div className="max-w-[1440px] w-full">
        <NoneBorderCard
          title="Power your event management experience!"
          titleClass={
            "section-title lg:w-[70%] xl:w-full animate__animated animate__fadeInLeft [font-family:'Montserrat',Helvetica] font-bold text-white tracking-[0] leading-[normal]"
          }
          description={
            <p className="section-text lg:w-[85%] xl:w-full section-">
              Simplify your workflow with an efficient, flexible, and
              easy-to-use event management platform that centralizes
              collaboration and streamlines productivity.
            </p>
          }
          descriptionClass={
            "animate__animated animate__fadeInLeft animate__delay-1s relative self-stretch [font-family:'Montserrat',Helvetica] font-normal text-white text-[22px] tracking-[0] leading-[31px]"
          }
          buttonTitle="Find Your Plans"
          btnTitleClass={
            "button-text text-white [font-family:'Poppins',Helvetica] font-semibold"
          }
          btnClass={`w-[60%] custom-btn primary text-center ${
            isActive
              ? ""
              : "animate__animated animate__fadeInLeft animate__delay-2s"
          }`}
          sectionClass={
            "items-start gap-[43px] flex flex-col relative z-10 mx-[10%] lg:mx-[12%] lg:mt-[3%] items-center lg:items-start lg:w-[425px] lg:gap-[45px] lg:justify-center"
          }
          path={"/plans"}
        />
      </div>
      <img
        src="/banner-bg.svg"
        alt=""
        className={`animate-pulse-plnify ${
          isActive && "keep"
        } absolute top-[0] lg:top-[45px] w-[100%]`}
      />
    </section>
  );
};
