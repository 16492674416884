import React, { useEffect, useState } from "react";

export const PrivacyPage = () => {
  return (
    <div className="relative flex flex-col items-center mt-20">
      <div className="text-[14px] max-w-[1080px] leading-normal flex flex-col p-4 px-6 gap-4 [font-family:'Montserrat',Helvetica]">
        <h1 className="font-bold text-center text-[22px]">
          PLNIFY PRIVACY POLICY
        </h1>
        <p>
          Please read the following to learn more about our Privacy Policy. By
          using the Plnify Technology LLC software, PLNIFY, and any other
          linked pages, features, content, or other services we offer
          (“Software”), you acknowledge that you accept the practices and
          policies outlined in this Privacy Policy, and you hereby consent that
          we will collect, use, and share your information according to our
          Privacy Policy. Any capitalized terms used herein without definition
          shall have the meaning given to them in the PLNIFY Terms and
          Conditions.
        </p>
        <p>
          This Privacy Policy covers our treatment of personally identifiable
          information (“Personal Information”) that we gather when you are
          accessing or using the Software. This policy does not apply to the
          practices of companies that we do not own or control, or to
          individuals that we do not employ or manage. This policy also does not
          apply to information you choose to disclose publicly on the Software.
        </p>
        <h2 className="font-bold">Personal Information PLNIFY Collects</h2>
        <p>
          We receive and store any information you provide to us. The types of
          Personal Information collected may include your real name, username,
          email address, IP address, location, browser information, browsing
          history, transactions, and any other information necessary for us to
          provide our services. If you provide any third-party account
          credentials to us, you understand some content and/or information in
          those accounts (“Third Party Account Information”) may be transmitted
          into your account with us if you authorize such transmissions, and
          that Third Party Account Information transmitted to Software is
          covered by this Privacy Policy. You can choose not to provide us with
          certain information, but then you may not be able to register with us
          or to take advantage of some of our features.
        </p>
        <h2 className="font-bold">
          Personal Information PLNIFY Collects Automatically
        </h2>
        <p>
          Whenever you interact with the Software, we automatically receive and
          record information on our server logs from your browser including your
          IP address, “cookie” information, and the page you requested.
          “Cookies” are identifiers we transfer to your device through our
          applications that allow us to recognize your device and tell us how
          and when pages in our site are visited and by how many people. We may
          also record the referring page or service that linked you to us; other
          information about the type of Web browser, computer, platform, related
          software, and settings you are using; any search terms you have
          entered on the Software; and other usage activity and data logged by
          our servers. You may be able to change the preferences on your device
          to prevent or limit your device’s acceptance of cookies, but this may
          prevent you from taking advantage of the Software’s best features.
          When we collect usage information (such as the numbers and frequency
          of visitors to the Software), we only use this data in aggregate form,
          and not in a manner that would identify you personally. For example,
          this aggregate data tells us how often users use parts of the
          Software, so that we can make the Software appealing to as many users
          as possible. We may also provide this aggregate information to our
          partners, so that they can understand how often people use their
          services and our Software, so that they, too, can provide you with an
          optimal online experience. We never disclose aggregate information to
          a partner in a manner that would identify you personally.
        </p>
        <h2 className="font-bold">Communications</h2>
        <p>
          As part of the Software and Services, you may receive communications
          from us, whether by email or other means. We and our agents may send
          you email, contact you, or engage you in other communication that we
          or they determine relate to your use of the Software.
        </p>
        <h2 className="font-bold">Affiliated businesses and Third Parties</h2>
        <p>
          In certain situations, businesses or third-party websites we’re
          affiliated with may sell items or provide services to you through the
          Software (either alone or jointly with us). You can recognize when an
          affiliated business is associated with such a transaction or service,
          and we will share your Personal Information with that affiliated
          business only to the extent that it is related to such transaction or
          service. One such service may include the ability for you to choose to
          automatically transmit Third Party Account Information to your Website
          profile or to automatically transmit information in your Website
          profile to your third-party account. We have no control over the
          policies and practices of third-party.
        </p>
        <h2 className="font-bold">Protections of PLNIFY & Others</h2>
        <p>
          We reserve the right to access, read, preserve, and disclose any
          information that we reasonably believe is necessary to comply with law
          or court order; enforce or apply our conditions of use and other
          agreements; or protect the rights, property, or safety of P.L.A.N.
          Technology LLC, our employees, our users, or others. This includes
          exchanging information with other companies and organizations for
          fraud protection and credit risk reduction.
        </p>
        <h2 className="font-bold">Personal Information Security</h2>
        <p>
          Your account is protected by a password for your privacy and security.
          If you access your account via a third-party site or service, you may
          have additional or different sign in protections via that third-party
          site or service. You need to prevent unauthorized access to your
          account and Personal Information by selecting and protecting your
          password and/or other sign in mechanism appropriately. We endeavor to
          protect the privacy of your account and other Personal Information we
          hold in our records, but we cannot guarantee complete security.
          Unauthorized entry or use, hardware or software failure, and other
          factors, may compromise the security of user information at any time.
          In the event that we believe that the security of your Personal
          Information in our possession or control may have been compromised, we
          may seek to notify you of that development. If a notification is
          appropriate, we will endeavor to do so as promptly as possible under
          the circumstances, and, we may do so by email. Using PLNIFY, you may
          find links to third-party websites or apps. We are not responsible for
          the privacy policies and/or practices on other sites or apps. When
          linking to another site or app you should read the site or apps
          privacy policy. User profile information, including without limitation
          your name, email address, and other information (“User Submissions”)
          may be displayed to other users if you chose to enter such information
          on your user profile. Again, any content you voluntarily disclose for
          other users to view on the Software is not Personal Information; it
          becomes publicly available and can be collected and used by others,
          and may be redistributed through the internet and other media
          channels.
        </p>
        <h2 className="font-bold">Accessible Personal Information</h2>
        <p>
          Through your user profile, you may access and, in some cases, edit or
          delete the following information you’ve provided to us (this list may
          change as the Software changes): real name, location, username,
          password, email address, and other account and user profile
          information. The information you can view, update, and delete through
          your user profile may change as the Software changes. If you have any
          questions about viewing or updating information we have on file about
          you, please contact us at{" "}
          <a className="text-[#00baf7]" href="mailto:support@plnify.com">
            support@plnify.com
          </a>
          .
        </p>
        <h2 className="font-bold">Other Information</h2>
        <p>
          You can always opt not to disclose information, but keep in mind that
          some information may be needed to register with us or to take
          advantage of some of our special features. You may be able to add,
          update, or delete certain information as explained in sections above.
          When you do so, however, we may maintain a copy of the unrevised
          information in our records. We may use any aggregated data derived
          from or incorporating your Personal Information after you update or
          delete it, but not in a manner that would identify you personally. You
          may close your account pursuant to the terms of the PLNIFY Terms of
          Service. Please note that some information may remain in our private
          records after the closing of your account. If you do not wish to
          receive email or other mail from us, please email us at{" "}
          <a className="text-[#00baf7]" href="mailto:support@plnify.com">
            support@plnify.com
          </a>
          .
        </p>
        <p>
          Please note that if you do not want to receive legal notices from us,
          such as this Privacy Policy, those legal notices will still govern
          your use of the Software, and you are responsible for reviewing such
          legal notices for changes.
        </p>
        <h2 className="font-bold">Changes to this Privacy Policy</h2>
        <p>
          We may amend this Privacy Policy from time to time. Use of information
          we collect now is subject to the Privacy Policy in effect at the time
          such information is used. If we make changes in the way we use
          Personal Information, we will notify you by posting an announcement on
          our Software or sending you an email. You are bound by any changes to
          the Privacy Policy when you use the Software after such changes have
          been first posted.
        </p>
        <h2 className="font-bold">Contact</h2>
        <p>
          If you have any questions about this privacy policy, you may contact
          us at{" "}
          <a className="text-[#00baf7]" href="mailto:support@plnify.com">
            support@plnify.com
          </a>
          .
        </p>
        <p>
          <em>Last Updated MAY 1, 2017</em>
        </p>
      </div>
    </div>
  );
};
