import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

import { Autoplay, Pagination, Navigation } from "swiper";

const data = [
  {
    id: "4",
    isActive: false,
    author: "Frida Karlsson",
    testimony:
      "“I absolutely love how Plnify eliminates the need for my team members to email me about upcoming ﬂights, hotels, or whatever else they may want to know before the itinerary goes out.”",
    position: "Tour Manager: Pitbull, Austin Mahone.",
    image: "/frida_karlsson.jpg",
  },
  // {
  //   id: "1",
  //   author: "Andres Albornoz",
  //   testimony:
  //     "“Thank you PLNIFY and all of your team for the 24/7 support and managing all of our information. We tried PLNIFY and we stayed with PLNIFY! The best app for tour and event management, it's user friendly and reliable!”",
  //   position: "Production Manager: Marc Anthony.",
  //   image: "/andres_albornoz.jpg",
  //   isActive: false,
  // },
  {
    id: "2",
    author: "Judd White",
    testimony:
      "“I've recently started using the Plnify platform and I'm super  impressed on the ease of inputing the data, the ﬂow thru the app  and the end product and how easy it has been to have everyone on  my team access the info. It’s concise with a very well planned out presentation to the end user, it just makes sense. A great tool that I’m looking forward to using on a regular basis.”",
    position:
      "Tour Manager/ Tour Accountant: Chris Cornell, Kanye West, Enrique Iglesias, Nicki Minaj, Fifth Harmony",
    image: "/judd_white.jpg",
    isActive: false,
  },
];

export const SwipperComponent = () => {
  const [screenSize, setScreenSize] = useState(window.screen.width);

  const [slides, setSlides] = useState({
    slp: 1,
    space: 0,
    spg: 1,
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenSize(window.screen.width);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setScreenSize(window.screen.width);
      });
    };
  }, []);

  return (
    <Swiper
      slidesPerView={slides.slp}
      spaceBetween={slides.space}
      slidesPerGroup={slides.spg}
      loop={true}
      loopFillGroupWithBlank={false}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={screenSize > 768 ? true : false}
      modules={[Autoplay, Navigation]}
    >
      {data.map((activeTestimony, index) => {
        return (
          <SwiperSlide key={index + activeTestimony}>
            <div
              // onTouchStart={handleTouchStart}
              // onTouchEnd={handleTouchEnd}
              // draggable={true}
              className="flex lg:flex-row flex-col items-center justify-between lg:gap-[20px] lg:px-[10%] lg:py-0"
            >
              {/* <div className="relative w-[300px] h-[300px] lg:mr-20">
                <div className="relative animate__animated animate__pulse w-[250px] h-[250px] lg:w-[280px] lg:h-[280px] bg-[url(https://c.animaapp.com/mTlbE0Ve/img/rectangle.svg)] bg-cover bg-no-repeat">
                  <div className="testimony-image  overflow-hidden w-[75%] h-[75%]  bg-[#1d2739] rounded-[100%]">
                    <img
                      src={activeTestimony.image}
                      alt=""
                      className="w-full"
                    />
                  </div>
                </div>
              </div> */}
              <div className="text-center animate__animated animate__fadeIn flex flex-col-reverse lg:flex-col items-center gap-[20px] lg:gap-[43px] relative self-stretch w-full h-[50%] lg:text-left">
                <p className="testimony-text relative self-stretch [font-family:'Poppins',Helvetica] font-normal text-[#4f5a68] tracking-[0] leading-[normal]">
                  {activeTestimony.testimony}
                </p>
                <div className="flex flex-col items-start gap-[6px] relative self-stretch w-full flex-[0_0_auto]">
                  <h2 className="text-center relative self-stretch [font-family:'Poppins',Helvetica] font-semibold text-[#283646] text-[1.2rem] lg:text-[1.1rem] tracking-[0] leading-[normal]">
                    {activeTestimony.author}
                  </h2>
                  <p className="text-center relative self-stretch [font-family:'Poppins',Helvetica] font-normal text-black text-[0.9rem] lg:text-[1rem] tracking-[0] leading-[normal]">
                    {activeTestimony.position}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
