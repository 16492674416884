import React, { useState } from "react";
import { useScrollToTop } from "../../../hooks/useScroll";
import PriceCard from "./PriceCard";
import "../style/style.css";

const card1Data = [
  "5 users",
  "1 Workspace",
  "Limited Features",
  "Files Storage Space",
];

const card2Data = [
  "Unlimited Users",
  "Unlimited Workspaces",
  "Priority Support",
  "Files Storage Space",
];

const BannerComponent = () => {
  const [isAnnually, setIsAnnually] = useState(false);

  const { alreadyScrolled, scrolledTop } = useScrollToTop(1000);

  const handleToggle = () => {
    setIsAnnually((prev) => !prev);
  };

  return (
    <section className="pb-10 w-full flex flex-col relative items-center overflow-hidden">
      <img
        src="/plans_banner.png"
        className="lg:w-full banner-image absolute top-[70px] lg:top-0 z-[-1]"
      />
      <div className="lg:max-w-[800px] lg:min-w-[700px] flex items-center justify-center flex-col gap-6 mt-[50px] lg:mt-[90px]">
        <h1 className="text-center relative mt-10 [font-family:'Montserrat',Helvetica] font-bold text-white text-[1.5rem] lg:text-[2rem] tracking-[0] leading-[normal]">
          Choose Your Plnify Plan
        </h1>
        {/* <p className="w-[80%] text-center [font-family:'Montserrat',Helvetica]  text-white text-[1rem] lg:text-[20px] leading-[normal">
          Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
          impedit quo minus id quod maxime placeat facere possimus
        </p> */}

        <div className="lg:w-[80%] gap-4 items-center flex justify-center font-[500] text-center [font-family:'Montserrat',Helvetica]  text-white text-[1rem] lg:text-[1.1rem] leading-[normal">
          <span>Monthly Billing</span>
          <div className={`toggle-container `} onClick={handleToggle}>
            <div className={`toggle-btn ${isAnnually ? "active" : ""} `}></div>
          </div>
          <span>Annually Billing</span>
        </div>
      </div>

      <div className="card-container flex-col-reverse gap-5 lg:flex-row mt-6 lg:mt-16">
        <PriceCard
          isAnnually={isAnnually}
          type={"outlined"}
          price="Free"
          storage={true}
          plan={"Basic"}
          description={card1Data}
          path="https://web.plnify.com/"
        />
        <PriceCard
          isAnnually={isAnnually}
          type={"primary"}
          price="39"
          storage={true}
          plan={"Standard"}
          description={card2Data}
          path="https://web.plnify.com/"
        />
        {/* <PriceCard
          isAnnually={isAnnually}
          type={"secondary"}
          price="39"
          plan={"Add Ons"}
          description={true}
          btnTitle="Upgrade Plan"
        /> */}
      </div>
      {!scrolledTop && !alreadyScrolled && (
        <div className="w-full flex items-center justify-center my-5 arrow-animation">
          <div className="bg-white shadow-lg rounded-full w-[70px] h-[70px] flex items-center justify-center">
            <img src="/down-arrow.svg" alt="arrow" />
          </div>
        </div>
      )}
    </section>
  );
};

export default BannerComponent;
