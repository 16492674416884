import React, { useState } from "react";

export const CollapseComponent = ({ answer, question }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="w-full p-8 collapse-container shadow-lg my-4">
      <div className="flex justify-between w-full">
        <h2 className={`collapse-title ${isActive && "actived"}`}>
          {question}
        </h2>
        <img
          className="collapse-toggle"
          src={isActive ? "./minus-icon.svg" : "./plus-icon.svg"}
          alt="plus icon"
          onClick={() => setIsActive((prev) => !prev)}
        />
      </div>

      <p className={`collapse-content ${isActive && "actived"}`}>{answer}</p>
    </div>
  );
};
