import React from "react";

export const ItemCard = ({
  type,
  title,
  subtitle,
  requirements,
  img,
  btnTitle,
  showBorder,
  plan,
  storage,
  description,
  isAnnually,
  btnAction,
}) => {
  return (
    <div
      className={`lg:h-full h-[700px] w-full lg:w-[500px] p-4 flex flex-col  justify-center items-center ${
        showBorder && "item-card-container"
      }`}
    >
      <img src={img} alt="card image" className="lg:h-[200px] h-[150px] " />
      <div
        className={`flex flex-col items-center ${
          type === "secondary" && "text-white"
        } justify-between h-[60%] w-[70%]`}
      >
        <div className="text-center flex flex-col card-head-container">
          <h3
            className={`animate__animated animate__bounceIn text-primary h-[45px] font-normal text-[1.4rem] md:text-[1.5rem]  ${type}`}
          >
            {subtitle}
          </h3>

          <h2
            className={`animate__animated animate__bounceIn text-primary h-[45px] text-[1.5rem] mb-1 md:text-[2rem] ${type}`}
          >
            {title}
          </h2>
        </div>
        <button
          type="button"
          onClick={btnAction}
          className={`btn cursor-pointer custom-btn ${type}`}
        >
          {btnTitle ?? "Get Started"}
        </button>
        <div className="text-center">
          <div className="storage-title text-center">
            <h2 className="mb-1">System Requirement</h2>
          </div>
          <div
            className={`item-card-description text-center font-normal mt-2 flex flex-col items-center justify-center`}
          >
            {requirements.map((requiment, index) => {
              return (
                <p
                  key={requiment + index}
                  className="text-center text-wrap w-[80%]"
                >
                  {requiment}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
