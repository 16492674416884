import React from "react";

const data = [
  {
    feature: "Multiple organizations",
    featureDescription: "",
    isNew: false,
    free: true,
    standard: true,
  },
  {
    feature: "Create and Manage Events",
    featureDescription:
      "Meetings, Itineraries, Flights, Hotels, Transportation, Attachments & Production",
    isNew: false,
    free: false,
    standard: true,
  },
  {
    feature: "Interactive Calendar",
    featureDescription: "Event Grouping & Color Coding",
    isNew: true,
    free: true,
    standard: true,
  },
  {
    feature: "Email & Export Calendars",
    featureDescription: "Itineraries, Event Details & Guest List",
    isNew: false,
    free: true,
    standard: true,
  },
  {
    feature: "Privacy & Permissions Settings",
    featureDescription: "",
    isNew: false,
    free: true,
    standard: true,
  },
  {
    feature: "Management flexibility",
    featureDescription: "Administrate Team Members",
    isNew: false,
    free: false,
    standard: true,
  },
  {
    feature: "Guest List",
    featureDescription: "",
    isNew: false,
    free: true,
    standard: true,
  },
  {
    feature: "Contacts Network with other teams",
    featureDescription: "",
    isNew: false,
    free: true,
    standard: true,
  },
  {
    feature: "Data Automation Faster data input",
    featureDescription: "",
    isNew: false,
    free: true,
    standard: true,
  },
  {
    feature: "Manage/Assign To-Do's",
    featureDescription: "",
    isNew: true,
    free: true,
    standard: true,
  },
  {
    feature: "Notifications & Activity Log",
    featureDescription: "",
    isNew: false,
    free: true,
    standard: true,
  },
  {
    feature: "Chat/Broadcast Messages",
    featureDescription: "Communicate directly with your team",
    isNew: false,
    free: true,
    standard: true,
  },
  {
    feature: "Ability to add reminders",
    featureDescription: "Customize alarms based on schedules/ personal needs",
    isNew: false,
    free: true,
    standard: true,
  },
  {
    feature: "Files Management/Cloud Storage Upload",
    featureDescription:
      "Integrate with Dropbox and Google Drive Manage and Share files",
    isNew: false,
    free: false,
    standard: true,
  },
  // {
  //   feature: "Spreadsheets (Rename)",
  //   featureDescription: "Customize Sheets/Open Format",
  //   isNew: true,
  //   free: true,
  //   standard: true,
  // },
  {
    feature: "User Profile",
    // featureDescription: "Customize Sheets/Open Format",
    isNew: false,
    free: true,
    standard: true,
  },
];
const TableComponent = ({ title, type, standard }) => {
  return (
    <div className="flex flex-col w-[100%] gap-[10px]">
      <h2 className="table-title lg:px-6 py-3">{title}</h2>
      <table className="w-[100%] text-sm text-left ">
        <thead className="table-header-title">
          <tr>
            <th scope="col" className="lg:px-6 py-3">
              Features
            </th>
            <th scope="col" className="lg:px-6 py-3 text-center">
              Free
            </th>
            {standard && (
              <th scope="col" className="lg:px-6 py-3 text-center">
                Standard
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr
                key={index + row.feature}
                className={`odd:bg-white ${
                  type === "primary" ? "even:bg-[#F7FAFF]" : "even:bg-[#F5FFFD]"
                }`}
              >
                <td className="lg:px-6 py-4 flex items-center">
                  <div className="flex flex-col w-[90%]">
                    <span className="table-row-title">{row.feature}</span>
                    <span className="table-row-description">
                      {row.featureDescription}
                    </span>
                  </div>
                  {row.isNew && (
                    <span
                      className={`table-row-new ${
                        type !== "primary" && "bg-[#32D1B1]"
                      }`}
                    >
                      New
                    </span>
                  )}
                </td>
                <td className="lg:px-6 py-4 text-center table-row-description">
                  {row.free ? (
                    <svg
                      width="15"
                      height="11"
                      viewBox="0 0 15 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.8714 1.24146L5.11042 10.0025L1.12842 6.02046"
                        stroke="#374665"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    "Limited"
                  )}
                </td>
                {standard && (
                  <td className="lg:px-6 py-4 text-center table-row-description">
                    {row.standard ? (
                      <svg
                        width="15"
                        height="11"
                        viewBox="0 0 15 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.8714 1.24146L5.11042 10.0025L1.12842 6.02046"
                          stroke="#374665"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <span>Limited</span>
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
