import React from "react";
import { NavLink } from "react-router-dom";

export const NoneBorderCard = ({
  title,
  description,
  buttonTitle,
  btnTitleClass,
  btnClass,
  titleClass,
  descriptionClass,
  sectionClass,
  buttonType,
  path,
  action,
  pathTarget,
}) => {
  return (
    <div className={sectionClass}>
      <h2 className={titleClass ?? ""}>{title}</h2>
      <div className={descriptionClass ?? ""}>{description}</div>
      <NavLink
        className={(isActive) =>
          `${btnClass ?? ""} ${btnTitleClass ?? ""}` + " btn "
        }
        onClick={action}
        to={path}
        target={pathTarget ?? "_self"}
      >
        {buttonTitle}
        {buttonType === "no-border" && (
          <img
            className="relative w-[17px] h-[14.73px] mr-[-1.00px]"
            alt="Arrow"
            src="https://c.animaapp.com/mTlbE0Ve/img/arrow-1-2.svg"
          />
        )}
      </NavLink>
    </div>
  );
};
