import React from "react";

const PriceCard = ({
  type,
  price,
  plan,
  btnTitle,
  storage,
  description,
  isAnnually,
  path,
}) => {
  return (
    <div className={`custom-card ${type} flex-col shadow-lg`}>
      {type === "primary" && (
        <span className="w-full py-2 text-center uppercase text-white tracking-[0.12em]">
          Recommended
        </span>
      )}
      <div
        className={`h-full inner-card p-0 ${
          type === "primary" ? "bg-white primary" : "bg-transparent"
        } flex items-center`}
      >
        
        <div
          className={`flex flex-col items-center w-full ${type === "secondary" && "text-white"} ${
            !storage ? "justify-center" : "justify-between"
          } h-[60%]`}
        >
          <span className={`badge mb-4 ${type}`}>{plan}</span>
          <div className="text-center flex flex-col justify-center">
            <span
              className={`animate__animated animate__bounceIn text-primary price-container ${type}`}
            >
              {price !== "Free" && <small>$</small>}
              {isAnnually && price !== "Free" ? "439" : price}
              {price !== "Free" && <small>{isAnnually ? "89" : "99"}</small>}
            </span>

            {price !== "Free" && (
              <p className="period-description lg:mb-5">
                {isAnnually ? "Annually" : "Monthly"}
              </p>
            )}
          </div>
          <div className="text-center">
            {storage && (
              <div className="storage-title text-center">
                <h2 className="mb-1">
                  {price !== "Free" ? "100 GB" : "15 GB"}
                </h2>
                <p>Files Storage Space</p>
              </div>
            )}
            {description && (
              <div
                className={`card-description text-center ${
                  storage && "mt-5 lg:mt-5 text-[.9rem]"
                }`}
              >
                {description.map((desc, index) => (
                  <p key={index + desc}>{desc}</p>
                ))}
              </div>
            )}
          </div>
        </div>
        <button
          type="button"
          className={`btn cursor-pointer custom-btn ${type}`}
          onClick={() => window.open(path, "_blank")}
        >
          {btnTitle ?? "Get Started"}
        </button>
      </div>
    </div>
  );
};

export default PriceCard;
